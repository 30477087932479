<template>
  <svg
    viewBox="0 0 50 50"
    width="47"
    height="47"
    xmlns="http://www.w3.org/2000/svg"
    fill-rule="evenodd"
    clip-rule="evenodd"
    stroke-miterlimit="10"
  >
    <path
      d="M43.826 48.629H6.237a4.803 4.803 0 01-4.802-4.803V6.237a4.802 4.802 0 014.802-4.802h37.589a4.802 4.802 0 014.803 4.802v37.589a4.804 4.804 0 01-4.803 4.803z"
      fill="none"
      stroke="#0f03fc"
      stroke-width="2.8"
    />
    <path
      d="M6.328 42.078V7.985c0-.909.748-1.657 1.657-1.657h34.093c.909 0 1.657.748 1.657 1.657v34.093c0 .909-.748 1.657-1.657 1.657H7.985a1.666 1.666 0 01-1.657-1.657z"
      fill="#e0e9fe"
      fill-rule="nonzero"
    />
    <g
      stroke="#8a8bfc"
      stroke-width="2.1"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M16.272 35.894v-13.14c0-.878.722-1.6 1.599-1.6h14.321c.877 0 1.599.722 1.599 1.6v13.14m-8.76-14.74v-8.29"
        fill="none"
      />
      <g fill="#fff">
        <circle cx="16.272" cy="33.18" r="4.718" />
        <circle cx="33.791" cy="33.18" r="4.718" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ConditionFork",
};
</script>
