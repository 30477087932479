<template>
  <div class="date-selector-wrapper border p-2 rounded">
    <span class="sidebar-panel__close js-cd-close" @click="close">Close</span>
    <div class="date-selector-dropdown-wrapper">
      <ul class="dropdown-nav">
        <li class="border-bottom">
          <h3 class="h3">
            Date presets
          </h3>
        </li>
        <li class="border-bottom" @click="setValue('range')">
          <span class="dropdown-item-link" title="Custom absolute range">
            <span class="item-text">Range</span>
          </span>
        </li>
        <li class="border-bottom" :class="(initialValue === 'today') ? 'active' : ''" @click="setValue('today')">
          <span class="dropdown-item-link" title="Today">
            <span class="item-text">Today</span>
          </span>
        </li>
        <li class="border-bottom" :class="(initialValue === 'yesterday') ? 'active' : ''" @click="setValue('yesterday')">
          <span class="dropdown-item-link" title="Yesterday">
            <span class="item-text">Yesterday</span>
          </span>
        </li>
        <li class="border-bottom" :class="(initialValue === 'week') ? 'active' : ''" @click="setValue('week')">
          <span class="dropdown-item-link" title="This week">
            <span class="item-text">This week</span>
          </span>
        </li>
        <li class="border-bottom" :class="(initialValue === 'month') ? 'active' : ''" @click="setValue('month')">
          <span class="dropdown-item-link" title="This month">
            <span class="item-text">This month</span>
          </span>
        </li>
        <li class="border-bottom" :class="(initialValue === 'quarter') ? 'active' : ''" @click="setValue('quarter')">
          <span class="dropdown-item-link" title="This quarter">
            <span class="item-text">This quarter</span>
          </span>
        </li>
        <li :class="initialValue.includes('last') || initialValue.includes('next') ? 'active' : ''" @click="setValue('custom')">
          <span class="dropdown-item-link" title="Last 48 hours">
            <span class="item-text">Custom range</span>
          </span>
        </li>
      </ul>
    </div>
    <flat-pickr
      v-if="showDatepicker"
      v-model="value"
      :config="config"
      class="form-control"
      name="completed_at"
    />
    <div v-else-if="showSummary">
      <p>{{ description }}</p>
    </div>
    <div v-else class="relative-data-selector p-4">
      <div class="days-selector">
        <select v-model="relative_option" class="form-control">
          <option value="last">
            Last
          </option>
          <option value="next">
            Next
          </option>
        </select>
        <input v-model="relative_days" class="form-control" placeholder="1" />
        <label class="control-label text-capitalize">Days</label>
      </div>
      <div class="description text-muted text-small">
        How many days
      </div>

      <div class="days-selector form-group">
        <label class="control-label text-capitalize">{{ relativeLabel }}</label>
        <input v-model="relative_before" class="form-control" placeholder="0" />
        <p>Days</p>
      </div>
      <div class="description text-muted text-small">
        Set before period
      </div>
    </div>
    <div></div>
    <button class="btn btn-sm btn-block btn-outline-secondary mb-4" @click="close">
      Done
    </button>
  </div>
</template>

<script>
export default {
  name: "DateSelector",
  props: {
    condition: {
      type: Object,
      default: () => {
        return {
          value: "",
        };
      },
    },
    initialValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      config: {
        altFormat: "Y-m-d",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
        inline: true,
        altInputClass: "invisible",
      },
      showDatepicker: true,
      showSummary: false,
      relative_option: "",
      relative_days: "1",
      relative_before: "0",
      value: "",
      selected: "",
      description: "",
      operator: "==",
    };
  },
  computed: {
    relativeLabel() {
      return this.relative_option === "last" ? "Before" : "After";
    },
  },
  watch: {
    value() {
      this.saveValue(this.value);
    },
    relative_option() {
      this.saveValue(this.getRelativeFormat());
    },
    relative_days() {
      this.saveValue(this.getRelativeFormat());
    },
    relative_before() {
      this.saveValue(this.getRelativeFormat());
    },
  },
  beforeMount() {
    if (this.initialValue !== "") {
      this.value = this.initialValue;
    }
  },
  methods: {
    setValue(value) {
      this.selected = value;
      this.operator = "isInRange";
      switch (value) {
        case "range":
          this.reset();
          this.showDatepicker = true;
          if (value.indexOf(" to ") === -1) {
            this.operator = "==";
          }
          break;
        case "custom":
          this.reset();
          break;
        case "today":
        case "yesterday":
          this.operator = "==";
        // eslint-disable-next-line
        default:
          this.reset();
          this.value = value;
          this.description = `Show data from the last ${value}`;
          if (value === "today" || value === "yesterday") {
            this.description = `Show data from ${value}`;
          }
          this.showSummary = true;
      }
    },
    reset() {
      this.fixed_value = "";
      this.description = ``;
      this.showDatepicker = false;
      this.showSummary = false;
    },
    saveValue(value) {
      this.operator = value.indexOf(" to ") > 0 ? "isInRange" : this.operator;
      const data = {
        value: value,
        operator: this.operator,
      };
      this.$emit("date-selected", data);
    },
    getRelativeFormat() {
      const delayName = this.relative_option === "last" ? "before" : "after";
      return `${this.relative_option} ${this.relative_days} ${delayName} ${this.relative_before}`;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.date-selector-wrapper {
  width: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  position: absolute;
  z-index: 999;
  background: white;
  right: 0;

  ul {
    padding-left: 0;
  }
  li {
    list-style: none;
    font-size: 0.9rem;
    padding: 0.3rem;

    &:hover {
      background: grey;
      color: white;
    }
  }

  > input {
    display: none;
  }

  .days-selector {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-gap: 0.5rem;
  }
  .sidebar-panel__close {
    position: absolute;
    right: -22px;
    top: -22px;
    z-index: 100;
  }
  .active {
    background: grey;
    color: white;
  }
}
</style>
