<template>
  <div class="card border-primary shadow-sm css-235sdfs">
    <div class="card-header">
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="media position-relative">
            <div v-if="validated" class="css-e2avkx">
              <span class="css-134uqom">
                <CheckmarkCircle />
              </span>
            </div>
            <ConditionFork class="mr-4" />
            <div class="media-body">
              <p
                class="small text-gray-700 font-weight-normal text-uppercase mb-0"
              >
                Condition
              </p>
              <p class="h3 mb-0">
                {{ conditionTitle }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div
            v-tooltip.left="'Remove condition'"
            class="float-right cursor-pointer"
            @click="deleteCondition(index)"
          >
            <Delete />
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="form-row mb-3">
        <div class="col-5">
          <label>Field</label>
          <FieldSelect
            :condition="condition"
            :model="model"
            @field-selected="setField"
          />
        </div>
        <div class="col-2">
          <label>Operator</label>
          <select v-model="condition.operator" class="form-control">
            <option
              :key="opr.code"
              v-for="opr in fieldOperators"
              :value="opr.code"
              :selected="condition.operator === opr.code ? 'selected' : ''"
            >
              {{ opr.name }}
            </option>
          </select>
        </div>
        <div v-if="condition.field" class="col-5">
          <label
            >Value
            <span v-if="field.type" class="text-muted"
              >({{ field.type }})</span
            ></label
          >
          <div v-if="field.type === 'date'">
            <div
              v-show="showDateSelector"
              class="date-selector-overlay"
              style="display: block;"
              @click="showDateSelector = false"
            />
            <input
              type="text"
              class="form-control"
              :value="condition.value"
              @click="showDateSelector = true"
            />
            <date-selector
              v-if="showDateSelector"
              :initialValue="condition.value"
              @date-selected="dateSelected"
              @close="showDateSelector = false"
            />
          </div>
          <div
            v-else-if="
              condition.type === 'product' &&
                condition.field === 'product_select'
            "
          >
            <!-- if products array has a message object (error message) -->
            <span v-if="products.message">
              <span class="text-danger">{{ products.message }}</span>
              <br>
              <router-link :to="{name: 'organization.integrations.index'}">Please integrate by logging into woocommerce</router-link>
            </span>
            <Spinner v-if="productsLoading" />
            <multiselect
              v-else
              v-model="value"
              placeholder=""
              label="name"
              track-by="name"
              :options="products"
              :option-height="104"
              :custom-label="customLabel"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="props">
                <span class="option__desc">
                  <span class="option__title">{{ props.option.name }}</span>
                </span>
              </template>
              <template slot="option" slot-scope="props">
                <img
                  v-if="props.option.src"
                  class="option__image"
                  :src="props.option.src"
                  alt="No Man’s Sky"
                />
                <DefaultImage
                  v-if="!props.option.src"
                  class="option__image__default"
                />
                <div class="option__desc">
                  <div class="option__title h4">
                    {{ props.option.name }}
                  </div>
                  <div class="option__small small">
                    {{ props.option.sku }}
                  </div>
                </div>
              </template>
            </multiselect>
          </div>
          <div v-else-if="field.type === 'option'">
            <select v-model="condition.value" class="form-control">
              <option
                v-for="opt in field.options"
                :key="opt.value"
                :value="opt.value"
              >
                {{ opt.label }}
              </option>
            </select>
          </div>
          <div v-else-if="field.type === 'tag'">
            <select v-model="condition.value" class="form-control">
              <option
                v-for="opt in field.options"
                :key="opt.slug"
                :value="opt.slug"
              >
                {{ opt.name }}
              </option>
            </select>
          </div>
          <div
            v-else-if="
              condition.operator === 'isInRange' ||
                condition.operator === 'isNotInRange'
            "
          >
            <input v-model="value" :type="'text'" class="form-control" />
          </div>
          <div v-else-if="field.id === 'total' || field.id === 'price' || field.id == 'AverageOrderValue'">
            <div class="input-group">
              <input v-model="value" :type="field.type" class="form-control" />
              <input
                class="input-group-text"
                type="number"
                required
                name="decimal"
                min="0"
                max="99"
                value="0"
                step="1"
                v-model="decimalValue"
                @input="decimalValueChange"
                @change="decimalValueChange"
              />
            </div>
          </div>
          <div v-else>
            <input
              v-model="value"
              :type="field.type"
              class="form-control"
              :readonly="field.disabled"
            />
          </div>
        </div>
        <div v-else class="col-5">
          <label>Value</label>
          <input type="text" class="form-control disabled" disabled="" />
        </div>
      </div>
      <div v-if="hasOperator">
        <p class="small mt-3 mb-0">
          {{ operatorDescription }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import jsonAPI from "@/api";
import operators from "@/helpers/operators";
import CheckmarkCircle from "@/components/Svg/CheckmarkCircle.vue";
import ConditionFork from "@/components/Svg/ConditionFork.vue";
import Delete from "@/components/Svg/Delete.vue";
import FieldSelect from "@/components/UI/FieldSelect.vue";
import DefaultImage from "@/components/Svg/DefaultImage.vue";
import DateSelector from "@/components/Automation/DateSelector.vue";
import Spinner from "@/components/UI/Spinner.vue";

export default {
  name: "Condition",
  components: {
    DateSelector,
    DefaultImage,
    Multiselect,
    ConditionFork,
    CheckmarkCircle,
    Delete,
    FieldSelect,
    Spinner,
  },
  props: {
    condition: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: null,
    },
    simple: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      config: {
        altFormat: "Y-m-d",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      value: "",
      valueName: "",
      decimalValue: "00",
      field: {},
      operators: operators,
      operator: { name: "Equal to", code: "==" },
      products: [],
      productsLoading: false,
      operator_name: "",
      showDateSelector: false,
    };
  },
  computed: {
    fieldOperators() {
      if (this.field && this.field.type) {
        return this.operators.filter((elem) => {
          return elem.type.includes(this.field.type);
        });
      }
      return [];
    },
    validated() {
      return (
        this.condition.field && this.condition.operator && this.condition.value
      );
    },
    conditionTitle: function() {
      if (!this.condition.field || !this.condition.value) {
        return "";
      }
      if (
        (this.field.id === "total" || this.field.id === "price") &&
        this.condition.operator !== "isInRange" &&
        this.condition.operator !== "isNotInRange"
      ) {
        // eslint-disable-next-line
        this.valueName = this.value + "." + this.decimalValue;
      }
      return `${this.field.title} ${this.operator_name} ${this.valueName}`;
    },
    hasOperator() {
      if (this.condition && this.condition.operator) {
        return this.operatorDescription != "";
      }
      return false;
    },
    operatorDescription() {
      if (this.condition && this.condition.operator) {
        return this.operators.filter((elem) => {
          return elem.code === this.condition.operator;
        })[0].desc;
      }
      return "";
    },
  },
  watch: {
    value(v) {
      this.setValue(v);
    },
    decimalValue(v) {
      this.setDecimalValue(v);
    },
    condition: function() {
      this.field.id = this.condition.field;
      this.field.search = this.condition.search;
      this.field.model = this.condition.type;
      this.field.type = this.condition.field_type;
      this.field.context = this.condition.context;
      this.field.value = this.condition.value;
      this.field.disabled = this.condition.disabled;

      this.field.title = this.condition.field;
      this.operator.code = this.condition.operator;
      this.valueName = this.condition.value;
      this.value = this.condition.value;

      const product = {};
      product.id = this.condition.value;
      product.name = this.condition.value;
      this.setValue(product);
    },
  },
  beforeMount: async function() {
    if (this.condition && this.condition.value) {
      this.value = this.condition.value;
      this.valueName = this.value;
      this.field.id = this.condition.field;
      this.field.search = this.condition.search;
      this.field.type = this.condition.type;
      this.field.context = this.condition.context;
      if (
        (this.field.id === "total" || this.field.id === "price") &&
        this.condition.operator !== "isInRange" &&
        this.condition.operator !== "isNotInRange"
      ) {
        let val = (this.value / 100).toFixed(2).split(".");
        // eslint-disable-next-line
        this.value = val[0];
        this.decimalValue = val[1];
      }
    }
    this.productsLoading = true;
    let uri = "/product";
    jsonAPI.get(uri).then((response) => {
      this.products = response.data;
      this.productsLoading = false;

      if (this.value && Array.isArray(this.products)) {
        const values = this.products.filter((product) => {
          return product.id === this.value;
        });
        if (values.length) {
          this.value = values.pop();
          this.valueName = this.value.name;
        }
      }
    });
  },
  methods: {
    decimalValueChange(event) {
      let newValue = event.target.value.trim();
      if (newValue > 99) {
        newValue = 99;
      }
      if (newValue.length < 0) {
        newValue = 0;
      }
      this.decimalValue = newValue;
    },
    setField(field) {
      console.log(field);
      this.field = field;
      if (field) {
        if (this.condition.field != field.id) {
          this.value = "";
        }
        if (field.value) {
          this.value = field.value;
        }
        this.condition.field = field.id;
        this.condition.search = field.search;
        this.condition.type = field.model;
        this.condition.field_type = field.type;
        this.condition.context = field.context ? field.context : "";
        this.condition.value = this.value ? this.value : "";
        if (field.id === "total" || field.id === "price") {
          this.condition.value = `${this.value}${this.decimalValue}`;
        }
        this.condition.disabled = field.disabled ? field.disabled : false;
      }
    },
    async deleteCondition(index) {
      this.$emit("deleteCondition", index);
    },
    customLabel({ title, sku }) {
      return `${title} – ${sku}`;
    },
    setValue(product) {
      switch (this.condition.field) {
        case "product_select":
          this.condition.value = product.id;
          //this.condition.field = 'product_id';
          this.valueName = product.name;
          break;
        case "email":
        case "type":
        case "status":
          this.condition.value = this.value.toLowerCase();
          this.valueName = this.condition.value;
          break;
        default:
          this.condition.value = this.value;
          if (this.field.id === "total" || this.field.id === "price") {
            this.condition.value = `${this.value}${this.decimalValue}`;
          }
          this.valueName = this.condition.value;
      }
    },
    setDecimalValue(value) {
      this.decimalValue = value;
      this.condition.value = `${this.value}${this.decimalValue}`;
    },
    dateSelected(date) {
      this.condition.value = date.value;
      this.condition.operator = date.operator;
    },
  },
};
</script>

<style>
.option__desc,
.option__image {
  display: inline-block;
  vertical-align: middle;
}
.option__image {
  max-height: 80px;
  margin-right: 10px;
}
.option__image__default {
  height: 60px;
  width: 60px;
  padding: 10px;
  margin-right: 10px;
}
.option__desc {
  padding: rem(10px);
}
.date-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  opacity: 0.3;
  background: black;
}
.input-group-text {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 80px;
}
</style>
