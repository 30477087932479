export default [
    {
        name: 'Exactly matches',
        code: '==',
        desc: 'If the entire value is found within',
        type: ['postcode', 'text', 'option']
    },
    {
        name: 'Does not exactly match',
        code: '!=',
        desc: 'If the entire value is not found within',
        type: ['postcode', 'text', 'option']
    },
    {
        name: 'Is equal to',
        code: '==',
        desc: 'If the entirely same value is found within',
        type: ['number', 'date']
    },
    {
        name: 'Does not equal',
        code: '!=',
        desc: 'If the entirely same value is not found within',
        type: ['number', 'date']
    },
    {
        name: 'Contains',
        code: 'contains',
        desc: 'If this value is found within, then return order or customer',
        type: ['postcode', 'text', 'tag', 'option']
    },
    {
        name: 'Does not contain',
        code: 'doesnotContain',
        desc: 'If this value is found within, then dont return order or customer',
        type: ['postcode', 'text', 'tag', 'option']
    },
    {
        name: 'Greater than',
        code: '>=',
        desc: 'Greater than or equal to',
        type: ['postcode', 'number']
    },
    {
        name: 'Less than',
        code: '<=',
        desc: 'Less than or equal to',
        type: ['postcode', 'number']
    },
    {
        name: 'Is in',
        code: 'isIn',
        desc: 'When using is in, separate your values with commas - Ex. 10,11,12,13',
        type: ['postcode', 'text', 'number', 'tag']
    },
    {
        name: 'Is not in',
        code: 'isNotIn',
        desc: 'When using is not in, separate your values with commas - Ex. 10,11,12,13',
        type: ['postcode', 'text', 'number', 'tag']
    },
    {
        name: 'Is in range',
        code: 'isInRange',
        desc: 'When using a range, split the upper and lower value with a : - Ex. 10:100',
        type: ['postcode', 'number', 'date']
    },
    {
        name: 'Is not in range',
        code: 'isNotInRange',
        desc: 'When using a range, split the upper and lower value with a : - Ex. 10:100',
        type: ['postcode', 'number', 'date']
    },
    {
        name: 'Is empty',
        code: 'isEmpty',
        desc: 'Matches if there is no value in the field',
        type: ['number', 'text', 'option']
    },
    {
        name: 'Is not empty',
        code: 'isNotEmpty',
        desc: 'Matches if there is any value in the field',
        type: ['number', 'text', 'option']
    }
]