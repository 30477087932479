<template>
  <multiselect
    v-model="field"
    :options="fieldOptions"
    group-values="options"
    group-label="title"
    :preselect-first="true"
    :group-select="false"
    placeholder="Type to search"
    track-by="title"
    label="title"
    :custom-label="fieldLabel"
    :show-labels="false"
  >
    <template slot="placeholder">
      <span class="option__desc">
        <span class="option__title">Type to search</span>
      </span>
    </template>
    <template slot="singleLabel" slot-scope="props">
      <span class="option__desc">
        <span class="option__title"
          >{{ props.option.category }} {{ props.option.title }}</span
        >
      </span>
    </template>
    <template slot="option" slot-scope="props">
      <span v-if="props.option.$isLabel">
        {{ props.option.$groupLabel }}
      </span>
      <div v-else class="option__desc">
        <span class="option__title text-dark"
          >{{ props.option.category }} {{ props.option.title }}</span
        >
        <span class="option__small ml-3 text-muted">{{
          props.option.desc
        }}</span>
      </div>
    </template>
    <span slot="noResult">No fields found.</span>
  </multiselect>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import jsonAPI from "@/api";

export default {
  name: "FieldSelect",
  components: { Multiselect },
  props: {
    condition: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    type: {
      type: String,
      required: false,
      default: "",
    },
    model: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      fieldOptions: [],
      field: "",
    };
  },
  computed: {
    ...mapGetters(["getConditions", "getConditionsByType"]),
  },
  watch: {
    field(v) {
      this.$emit("field-selected", v);
    },
    model() {
      this.field = "";
      this.filterConditions();
    },
  },
  beforeMount: async function() {
    await this.filterConditions();
    if (this.condition.field) {
      this.fieldOptions.filter((elem) => {
        if (elem.model.toLowerCase() === this.condition.type) {
          elem.options.filter((elemSub) => {
            if (elemSub.id === this.condition.field) {
              this.field = elemSub;
            }
          });
        }
      });
    }
  },
  methods: {
    fieldLabel(option) {
      return `${option.category} ${option.title} – ${option.desc}`;
    },
    async filterConditions() {
      const options = await this.loadConditions();
      this.fieldOptions = options.reduce((previousValue, currentValue) => {
        if (currentValue.used_by.includes(this.model)) {
          previousValue.push(currentValue);
        }
        return previousValue;
      }, []);
    },
    async loadConditions() {
      let conditionsResult = await jsonAPI.get("/setup/condition");
      let conditions = conditionsResult.data;
      if (this.model) {
        conditions = conditionsResult.data.filter((elemSub) => {
          if(elemSub.used_by) {
            return elemSub.used_by.includes(this.model);
          }
        });        
      }
      return conditions;
    },
  },
};
</script>

<style scoped></style>
