<template>
  <svg viewBox="0 0 512 512">
    <path
      d="M498 253C498 116 388 4 252 4 117 4 7 116 7 253c0 138 110 250 245 250 136 0 246-112 246-250zm-272 37l119-120c14-13 35-13 48 0s13 34 0 48L262 349c-2 2-4 4-7 5-2 3-3 5-6 7-13 14-34 13-48 0l-71-71c-13-14-13-35 0-48s35-13 48 0z"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckmarkCircle",
};
</script>

<style scoped></style>
